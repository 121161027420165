import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.includes.js";
import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, mergeProps as _mergeProps, resolveDirective as _resolveDirective, withDirectives as _withDirectives, unref as _unref, createVNode as _createVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
import _imports_0 from '@/assets/logo-white.svg';
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-5f81d9e0"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "navbar-overlap navbar navbar-container"
};
var _hoisted_2 = {
  "class": "container-xl"
};
var _hoisted_3 = {
  key: 1,
  "class": "ml-auto border-1 surface-border border-round surface-100 text-xs p-1"
};
var _hoisted_4 = {
  key: 0,
  "class": "flex align-items-center gap-2"
};
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { useAuthStore } from "@/stores/authStore";
import { computed, ref, watch } from "vue";
export default {
  __name: 'MegaMenuNavigation',
  setup: function setup(__props) {
    var router = useRouter();
    var _useI18n = useI18n(),
      t = _useI18n.t;
    var authStore = useAuthStore();
    var hasPermission = function hasPermission(permission) {
      var _authStore$permission, _authStore$permission2;
      return (_authStore$permission = (_authStore$permission2 = authStore.permissions) === null || _authStore$permission2 === void 0 ? void 0 : _authStore$permission2.includes(permission)) !== null && _authStore$permission !== void 0 ? _authStore$permission : false;
    };
    var hasRole = function hasRole(role) {
      var _authStore$roles$incl, _authStore$roles;
      return (_authStore$roles$incl = (_authStore$roles = authStore.roles) === null || _authStore$roles === void 0 ? void 0 : _authStore$roles.includes(role)) !== null && _authStore$roles$incl !== void 0 ? _authStore$roles$incl : false;
    };
    var categories = computed(function () {
      var _authStore$user$categ, _authStore$user;
      return (_authStore$user$categ = (_authStore$user = authStore.user) === null || _authStore$user === void 0 ? void 0 : _authStore$user.categories) !== null && _authStore$user$categ !== void 0 ? _authStore$user$categ : [];
    });
    var getMenuItems = function getMenuItems() {
      return [{
        label: t("navigation.main.home").toLocaleUpperCase(),
        "class": "fw-bold",
        command: function command() {
          return router.push({
            name: "HomePage"
          });
        }
      }, {
        label: "Trenera profils".toLocaleUpperCase(),
        "class": "fw-bold",
        visible: categories.value.length > 0 && (hasRole("dressage instructor") || hasRole("show jumping instructor")),
        items: categories.value.length > 0 ? categories.value.map(function (category) {
          return {
            label: category.name,
            "class": "fw-normal",
            command: function command() {
              return router.push({
                name: "InstructorProfile",
                params: {
                  id: category.instructor_id
                }
              });
            }
          };
        }) : [{
          label: t("navigation.loading"),
          disabled: true
        }]
      }, {
        label: t("navigation.admin.header").toLocaleUpperCase(),
        "class": "fw-bold",
        visible: hasPermission("access admin dashboard"),
        items: [{
          label: t("navigation.admin.home"),
          icon: "bi bi-house-gear-fill",
          "class": "fw-normal",
          command: function command() {
            return router.push({
              name: "AdminDashboard"
            });
          }
        }, {
          label: t("navigation.admin.access"),
          icon: "bi bi-people-fill",
          "class": "fw-normal",
          visible: hasPermission("manage users") || hasPermission("manage permissions") || hasPermission("manage roles"),
          items: [{
            label: t("navigation.admin.users"),
            icon: "pi pi-users",
            command: function command() {
              return router.push({
                name: "UserList"
              });
            },
            visible: hasPermission("manage users")
          }, {
            label: t("navigation.admin.permissions"),
            icon: "pi pi-key",
            command: function command() {
              return router.push({
                name: "PermissionList"
              });
            },
            visible: hasPermission("manage permissions")
          }, {
            label: t("navigation.admin.roles"),
            icon: "pi pi-briefcase",
            command: function command() {
              return router.push({
                name: "RoleList"
              });
            },
            visible: hasPermission("manage roles")
          }]
        }, {
          label: t("navigation.admin.geolocation"),
          icon: "bi bi-compass-fill",
          "class": "fw-normal",
          visible: hasPermission("manage countries") || hasPermission("manage cities"),
          items: [{
            label: t("navigation.admin.countries"),
            icon: "pi pi-globe",
            command: function command() {
              return router.push({
                name: "CountryList"
              });
            },
            visible: hasPermission("manage countries")
          }, {
            label: t("navigation.admin.cities"),
            icon: "pi pi-map",
            command: function command() {
              return router.push({
                name: "CityList"
              });
            },
            visible: hasPermission("manage cities")
          }]
        }, {
          label: t("navigation.admin.trainings"),
          icon: "bi bi-suitcase-lg-fill",
          "class": "fw-normal",
          visible: hasPermission("manage instructors") || hasPermission("manage certificates") || hasPermission("manage categories"),
          items: [{
            label: t("navigation.admin.instructors"),
            icon: "bi bi-person-badge",
            command: function command() {
              return router.push({
                name: "InstructorList"
              });
            },
            visible: hasPermission("manage instructors")
          }, {
            label: t("navigation.admin.certificates"),
            icon: "bi bi-patch-check",
            command: function command() {
              return router.push({
                name: "CertificateList"
              });
            },
            visible: hasPermission("manage certificates")
          }, {
            label: t("navigation.admin.categories"),
            icon: "bi bi-tags",
            command: function command() {
              return router.push({
                name: "CategoryList"
              });
            },
            visible: hasPermission("manage categories")
          }]
        }, {
          label: t("navigation.admin.events"),
          icon: "bi bi-calendar-event-fill",
          "class": "fw-normal",
          visible: hasPermission("manage events") || hasPermission("manage event categories") || hasPermission("manage event types") || hasPermission("manage locations"),
          items: [{
            label: t("navigation.admin.events"),
            icon: "pi pi-calendar",
            command: function command() {
              return router.push({
                name: "EventList"
              });
            },
            visible: hasPermission("manage events")
          }, {
            label: t("navigation.admin.event_categories"),
            icon: "pi pi-sitemap",
            command: function command() {
              return router.push({
                name: "EventCategoryList"
              });
            },
            visible: hasPermission("manage event_categories")
          }, {
            label: t("navigation.admin.event_types"),
            icon: "pi pi-sliders-h",
            command: function command() {
              return router.push({
                name: "EventTypeList"
              });
            },
            visible: hasPermission("manage event_types")
          }, {
            label: t("navigation.admin.locations"),
            icon: "pi pi-map-marker",
            command: function command() {
              return router.push({
                name: "LocationList"
              });
            },
            visible: hasPermission("manage locations")
          }]
        }].filter(function (item) {
          return item.visible !== false;
        })
      }];
    };
    var menuItems = ref(getMenuItems());
    watch(function () {
      return [authStore.permissions, authStore.roles, categories.value];
    }, function () {
      menuItems.value = getMenuItems();
    }, {
      deep: true
    });
    var computedMenuItems = computed(function () {
      return menuItems.value;
    });
    return function (_ctx, _cache) {
      var _component_Badge = _resolveComponent("Badge");
      var _component_Avatar = _resolveComponent("Avatar");
      var _component_Button = _resolveComponent("Button");
      var _component_Menubar = _resolveComponent("Menubar");
      var _directive_ripple = _resolveDirective("ripple");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_Menubar, {
        model: computedMenuItems.value
      }, {
        start: _withCtx(function () {
          return [_createElementVNode("img", {
            "class": "h-2rem",
            src: _imports_0,
            style: {
              "width": "50px",
              "height": "auto"
            },
            onClick: _cache[0] || (_cache[0] = function () {
              return _ctx.changeLocale && _ctx.changeLocale.apply(_ctx, arguments);
            })
          })];
        }),
        item: _withCtx(function (_ref) {
          var item = _ref.item,
            props = _ref.props,
            hasSubmenu = _ref.hasSubmenu,
            root = _ref.root;
          return [item.visible !== false ? _withDirectives((_openBlock(), _createElementBlock("a", _mergeProps({
            key: 0,
            "class": "flex align-items-center h-full"
          }, props.action), [_createElementVNode("span", {
            "class": _normalizeClass(item.icon)
          }, null, 2), _createElementVNode("span", {
            "class": _normalizeClass(item.icon ? 'ml-2 ' : '' + item["class"])
          }, _toDisplayString(item.label), 3), item.badge ? (_openBlock(), _createBlock(_component_Badge, {
            key: 0,
            "class": _normalizeClass({
              'ml-auto': !root,
              'ml-2': root
            }),
            value: item.badge
          }, null, 8, ["class", "value"])) : _createCommentVNode("", true), item.shortcut ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(item.shortcut), 1)) : _createCommentVNode("", true), hasSubmenu ? (_openBlock(), _createElementBlock("i", {
            key: 2,
            "class": _normalizeClass(['pi pi-angle-down', {
              'pi-angle-down ml-2': root,
              'pi-angle-right ml-auto': !root
            }])
          }, null, 2)) : _createCommentVNode("", true)], 16)), [[_directive_ripple]]) : _createCommentVNode("", true)];
        }),
        end: _withCtx(function () {
          var _unref$user;
          return [_unref(authStore).user ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createVNode(_component_Avatar, {
            image: (_unref$user = _unref(authStore).user) === null || _unref$user === void 0 ? void 0 : _unref$user.profile_picture,
            "class": "cursor-pointer",
            shape: "circle",
            onClick: _cache[1] || (_cache[1] = function ($event) {
              return _ctx.$router.push({
                name: "UserProfile"
              });
            })
          }, null, 8, ["image"]), _createVNode(_component_Button, {
            "class": "bg-white text-primary hover-black",
            label: "IZIET",
            rounded: "",
            onClick: _cache[2] || (_cache[2] = function ($event) {
              return _unref(authStore).logout();
            })
          })])) : _createCommentVNode("", true)];
        }),
        _: 1
      }, 8, ["model"])])]);
    };
  }
};