import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue";
export default {
  __name: 'inputError',
  props: {
    errors: {
      type: Array,
      required: true
    }
  },
  setup: function setup(__props) {
    return function (_ctx, _cache) {
      var _component_InlineMessage = _resolveComponent("InlineMessage");
      return _openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.errors, function (error, index) {
        return _openBlock(), _createElementBlock("div", {
          key: index
        }, [_createVNode(_component_InlineMessage, null, {
          "default": _withCtx(function () {
            return [_createTextVNode(_toDisplayString(error), 1)];
          }),
          _: 2
        }, 1024)]);
      }), 128);
    };
  }
};