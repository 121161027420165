import _toConsumableArray from "/var/www/jjs_datu_uzskaites_un_parvaldibas_sistema/frontend/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.array.for-each.js";
import "core-js/modules/es.array.is-array.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Transition as _Transition, Fragment as _Fragment } from "vue";
var _hoisted_1 = {
  id: "app"
};
var _hoisted_2 = {
  "class": "container-lg"
};
var _hoisted_3 = {
  "class": "hidden lg:flex justify-content-end mt-2 mb-3"
};
var _hoisted_4 = ["href", "onClick"];
var _hoisted_5 = {
  "class": "font-semibold"
};
var _hoisted_6 = ["href", "target"];
import { computed, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useFetchDataStore } from "@/stores/fetchDataStore";
import { useI18n } from "vue-i18n";
import routeTranslationsLV from "@/locales/lv/routes.json";
import routeTranslationsEN from "@/locales/en/routes.json";
import "vue-loading-overlay/dist/css/index.css";
export default {
  __name: 'App',
  setup: function setup(__props) {
    var router = useRouter();
    var route = useRoute();
    var fetchDataStore = useFetchDataStore();
    var locale = useI18n();
    var isFetching = computed(function () {
      return fetchDataStore.isFetching;
    });
    var routeTranslations = ref(routeTranslationsLV);

    /**
     * Resolve the breadcrumb based on the given name.
     */
    var resolveBreadcrumb = function resolveBreadcrumb(name) {
      routeTranslations.value = locale.locale.value === "lv" ? routeTranslationsLV : routeTranslationsEN;
      var breadcrumb = routeTranslations.value[name];
      return !(breadcrumb === undefined) ? Array.isArray(breadcrumb) ? breadcrumb : [breadcrumb] : [{
        name: name
      }];
    };

    /**
     * Return an array of breadcrumb objects based on the current route.
     */
    var crumbs = computed(function () {
      var matchedRoutes = router.resolve({
        path: route.fullPath
      }).matched;
      var crumbs = [];
      matchedRoutes.forEach(function (matchedRoute) {
        if (matchedRoute.name && routeTranslations.value[matchedRoute.name]) {
          var breadcrumb = resolveBreadcrumb(matchedRoute.name);
          if (breadcrumb) {
            breadcrumb = breadcrumb.map(function (breadcrumb, i, arr) {
              var path = matchedRoute.redirect ? typeof matchedRoute.redirect === "string" ? matchedRoute.redirect : matchedRoute.redirect.path : matchedRoute.path;
              return {
                label: breadcrumb.label,
                route: breadcrumb.route || route,
                isActive: i === arr.length - 1 && matchedRoute === matchedRoutes[matchedRoutes.length - 1]
              };
            });
            crumbs.push.apply(crumbs, _toConsumableArray(breadcrumb));
          }
        }
      });
      return crumbs;
    });
    var home = ref({
      icon: "bi bi-house-door-fill",
      route: "/"
    });
    return function (_ctx, _cache) {
      var _component_MegaMenuNavigation = _resolveComponent("MegaMenuNavigation");
      var _component_Toast = _resolveComponent("Toast");
      var _component_router_link = _resolveComponent("router-link");
      var _component_Breadcrumb = _resolveComponent("Breadcrumb");
      var _component_router_view = _resolveComponent("router-view");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_MegaMenuNavigation), _createElementVNode("div", _hoisted_1, [_createVNode(_component_Toast), _createElementVNode("main", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createVNode(_Transition, {
        mode: "out-in",
        name: "fade"
      }, {
        "default": _withCtx(function () {
          return [_unref(useFetchDataStore)().show ? (_openBlock(), _createBlock(_component_Breadcrumb, {
            key: 0,
            home: home.value,
            model: crumbs.value,
            "class": "text-white"
          }, {
            item: _withCtx(function (_ref) {
              var item = _ref.item,
                props = _ref.props;
              return [item.route ? (_openBlock(), _createBlock(_component_router_link, {
                key: 0,
                to: item.route,
                custom: ""
              }, {
                "default": _withCtx(function (_ref2) {
                  var href = _ref2.href,
                    navigate = _ref2.navigate;
                  return [_createElementVNode("a", _mergeProps({
                    href: href
                  }, props.action, {
                    onClick: navigate
                  }), [_createElementVNode("span", {
                    "class": _normalizeClass(item.icon)
                  }, null, 2), _createElementVNode("span", _hoisted_5, _toDisplayString(item.label), 1)], 16, _hoisted_4)];
                }),
                _: 2
              }, 1032, ["to"])) : (_openBlock(), _createElementBlock("a", _mergeProps({
                key: 1,
                href: item.url,
                target: item.target
              }, props.action), [_createElementVNode("span", null, _toDisplayString(item.label), 1)], 16, _hoisted_6))];
            }),
            _: 1
          }, 8, ["home", "model"])) : _createCommentVNode("", true)];
        }),
        _: 1
      })]), _createVNode(_component_router_view)])])], 64);
    };
  }
};