import "core-js/modules/es.function.name.js";
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  "class": "row text-start needs-validation"
};
var _hoisted_2 = {
  "class": "col-12 md:col-8"
};
var _hoisted_3 = {
  "class": "flex flex-column gap-1"
};
var _hoisted_4 = {
  "for": "name"
};
var _hoisted_5 = {
  "class": "col-12 md:col-2"
};
var _hoisted_6 = {
  "class": "flex flex-column gap-1"
};
var _hoisted_7 = {
  "for": "stables"
};
var _hoisted_8 = {
  "class": "col-12 md:col-2"
};
var _hoisted_9 = {
  "class": "flex flex-column gap-1"
};
var _hoisted_10 = {
  "for": "stables"
};
var _hoisted_11 = {
  "class": "col-12"
};
var _hoisted_12 = {
  "class": "flex flex-column gap-1"
};
var _hoisted_13 = {
  "for": "horse_capacity"
};
import { computed } from "vue";
import { useFetchDataStore } from "@/stores/fetchDataStore";
import { useErrorStore } from "@/stores/errorStore";
import AdminForm from "@/components/AdminForm.vue";
import InputError from "@/components/error/inputError.vue";
export default {
  __name: 'CreateLocation',
  setup: function setup(__props) {
    var fetchDataStore = useFetchDataStore();
    var instance = computed(function () {
      return fetchDataStore.instance;
    });
    var errorStore = useErrorStore();
    var errorList = computed(function () {
      return errorStore.errorList;
    });
    return function (_ctx, _cache) {
      var _component_InputText = _resolveComponent("InputText");
      var _component_ToggleButton = _resolveComponent("ToggleButton");
      var _component_Slider = _resolveComponent("Slider");
      return _openBlock(), _createBlock(AdminForm, {
        "database-table": "locations",
        "model-name": "Location",
        "page-name": _ctx.$t("pageHeadings.locations.locations"),
        "short-desc": _ctx.$t("pageHeadings.locations.create location")
      }, {
        "default": _withCtx(function () {
          return [_createElementVNode("form", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.$t("fields.locations.name")), 1), _createVNode(_component_InputText, {
            id: "name",
            modelValue: _unref(instance).name,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
              return _unref(instance).name = $event;
            }),
            invalid: _unref(errorList).name
          }, null, 8, ["modelValue", "invalid"]), _createVNode(InputError, {
            errors: _unref(errorList).name
          }, null, 8, ["errors"])])]), _createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, [_createElementVNode("label", _hoisted_7, _toDisplayString(_ctx.$t("fields.locations.stables")), 1), _createVNode(_component_ToggleButton, {
            id: "stables",
            modelValue: _unref(instance).stables,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
              return _unref(instance).stables = $event;
            }),
            invalid: _unref(errorList).stables,
            offLabel: _ctx.$t("no"),
            onLabel: _ctx.$t("yes"),
            offIcon: "pi pi-times",
            onIcon: "pi pi-check"
          }, null, 8, ["modelValue", "invalid", "offLabel", "onLabel"])])]), _createElementVNode("div", _hoisted_8, [_createElementVNode("div", _hoisted_9, [_createElementVNode("label", _hoisted_10, _toDisplayString(_ctx.$t("fields.locations.competitions")), 1), _createVNode(_component_ToggleButton, {
            id: "stables",
            modelValue: _unref(instance).competitions,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) {
              return _unref(instance).competitions = $event;
            }),
            change: !_unref(instance).stables ? _unref(instance).horse_capacity = 0 : null,
            invalid: _unref(errorList).competitions,
            offLabel: _ctx.$t("no"),
            onLabel: _ctx.$t("yes"),
            offIcon: "pi pi-times",
            onIcon: "pi pi-check"
          }, null, 8, ["modelValue", "change", "invalid", "offLabel", "onLabel"])])]), _createElementVNode("div", _hoisted_11, [_createElementVNode("div", _hoisted_12, [_createElementVNode("label", _hoisted_13, _toDisplayString(_ctx.$t("fields.locations.horse_capacity")), 1), _createVNode(_component_InputText, {
            modelValue: _unref(instance).horse_capacity,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = function ($event) {
              return _unref(instance).horse_capacity = $event;
            }),
            modelModifiers: {
              number: true
            },
            disabled: !_unref(instance).stables,
            "class": "w-full mb-2"
          }, null, 8, ["modelValue", "disabled"]), _createVNode(_component_Slider, {
            modelValue: _unref(instance).horse_capacity,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = function ($event) {
              return _unref(instance).horse_capacity = $event;
            }),
            disabled: !_unref(instance).stables,
            "class": "mx-2"
          }, null, 8, ["modelValue", "disabled"])])])])];
        }),
        _: 1
      }, 8, ["page-name", "short-desc"]);
    };
  }
};