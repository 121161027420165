import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  key: 0,
  "class": "container-xl mt-3 lg:mt-0"
};
var _hoisted_2 = {
  "class": "grid"
};
var _hoisted_3 = {
  "class": "col-12 lg:col-3"
};
var _hoisted_4 = {
  "class": "grid"
};
var _hoisted_5 = {
  "class": "col-12 h-full"
};
var _hoisted_6 = {
  "class": "grid"
};
var _hoisted_7 = {
  "class": "col-12"
};
var _hoisted_8 = {
  "class": "p-2 bg-primary lg:bg-white rounded shadow text-center h-full"
};
var _hoisted_9 = {
  "class": "m-0 text-white lg:text-primary"
};
var _hoisted_10 = {
  "class": "col-12 lg:col-9"
};
var _hoisted_11 = {
  "class": "p-3 bg-white rounded shadow h-full"
};
import { onBeforeMount, onMounted } from "vue";
import { useFetchDataStore } from "@/stores/fetchDataStore";
import { useAuthStore } from "@/stores/authStore";
import UserInfoTab from "@/components/user/UserInfoTab.vue";
import ChangePasswordTab from "@/components/user/ChangePasswordTab.vue";
import AddressTab from "@/components/user/AddressTab.vue";
export default {
  __name: 'UserProfileEdit',
  setup: function setup(__props) {
    var fetchDataStore = useFetchDataStore();
    var authStore = useAuthStore();
    onBeforeMount(function () {
      fetchDataStore.resetInstance();
      fetchDataStore.instance = authStore.user;
    });
    onMounted(function () {
      fetchDataStore.showComponents();
    });
    return function (_ctx, _cache) {
      var _component_TabPanel = _resolveComponent("TabPanel");
      var _component_TabView = _resolveComponent("TabView");
      return _openBlock(), _createBlock(_Transition, {
        mode: "out-in",
        name: "fade"
      }, {
        "default": _withCtx(function () {
          return [_unref(fetchDataStore).show ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, [_createElementVNode("div", _hoisted_7, [_createElementVNode("div", _hoisted_8, [_createElementVNode("h1", _hoisted_9, _toDisplayString(_ctx.$t("user_profile").toLocaleUpperCase()), 1)])])])])])]), _createElementVNode("div", _hoisted_10, [_createElementVNode("div", _hoisted_11, [_createVNode(_component_TabView, null, {
            "default": _withCtx(function () {
              return [_createVNode(_component_TabPanel, {
                header: _ctx.$t("tabpanel.user.info")
              }, {
                "default": _withCtx(function () {
                  return [_createVNode(UserInfoTab)];
                }),
                _: 1
              }, 8, ["header"]), _createVNode(_component_TabPanel, {
                header: _ctx.$t("tabpanel.user.change_password")
              }, {
                "default": _withCtx(function () {
                  return [_createVNode(ChangePasswordTab)];
                }),
                _: 1
              }, 8, ["header"]), _createVNode(_component_TabPanel, {
                header: _ctx.$t("tabpanel.user.address")
              }, {
                "default": _withCtx(function () {
                  return [_createVNode(AddressTab)];
                }),
                _: 1
              }, 8, ["header"])];
            }),
            _: 1
          })])])])])) : _createCommentVNode("", true)];
        }),
        _: 1
      });
    };
  }
};