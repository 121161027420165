import "core-js/modules/es.function.name.js";
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
var _hoisted_1 = {
  "class": "row gap-3 py-3 text-start needs-validation"
};
var _hoisted_2 = {
  "class": "col-12"
};
var _hoisted_3 = {
  "class": "flex flex-column gap-1"
};
var _hoisted_4 = {
  "for": "name"
};
import { computed } from "vue";
import { useFetchDataStore } from "@/stores/fetchDataStore";
import { useErrorStore } from "@/stores/errorStore";
import AdminForm from "@/components/AdminForm.vue";
import InputError from "@/components/error/inputError.vue";
export default {
  __name: 'EditEventType',
  setup: function setup(__props) {
    var fetchDataStore = useFetchDataStore();
    var instance = computed(function () {
      return fetchDataStore.instance;
    });
    var errorStore = useErrorStore();
    var errorList = computed(function () {
      return errorStore.errorList;
    });
    return function (_ctx, _cache) {
      var _component_InputText = _resolveComponent("InputText");
      return _ctx.can('edit instances') && _ctx.can('manage event_types') ? (_openBlock(), _createBlock(AdminForm, {
        key: 0,
        "database-table": "event_types",
        "model-name": "EventType",
        "page-name": _ctx.$t("pageHeadings.event_types.event_types"),
        "short-desc": _ctx.$t("pageHeadings.event_types.edit event_type")
      }, {
        "default": _withCtx(function () {
          return [_createElementVNode("form", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.$t("fields.event_types.name")), 1), _createVNode(_component_InputText, {
            id: "name",
            modelValue: _unref(instance).name,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
              return _unref(instance).name = $event;
            }),
            disabled: _unref(fetchDataStore).isFetching || _unref(fetchDataStore).isProcessing,
            invalid: _unref(errorList).name
          }, null, 8, ["modelValue", "disabled", "invalid"]), _createVNode(InputError, {
            errors: _unref(errorList).name
          }, null, 8, ["errors"])])])])];
        }),
        _: 1
      }, 8, ["page-name", "short-desc"])) : _createCommentVNode("", true);
    };
  }
};