import "core-js/modules/es.error.to-string.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.index-of.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.date.to-string.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
export default {
  data: function data() {
    return {
      bigNum: 0,
      smallNum: 0,
      countOfCells: 0,
      result: [],
      diapozoneResult: [],
      sumNum1: 0,
      sumNum2: 0,
      sumResult: 0
    };
  },
  methods: {
    digitsAfterPoint: function digitsAfterPoint(num) {
      var str = num.toString();
      if (str.indexOf('.') != -1) {
        return Number('0.' + str.split('.')[1]);
      } else {
        return 0;
      }
    },
    getResult: function getResult() {
      this.result = [];
      this.diapozoneResult = [];
      var tempResult = (this.bigNum - this.smallNum) / this.countOfCells;
      var hasDigitsAfterDecimal = this.digitsAfterPoint(tempResult);
      if (hasDigitsAfterDecimal != 0) {
        var limit = Math.round(hasDigitsAfterDecimal * this.countOfCells);
        var _index = 1;
        for (var i = 0; i < this.countOfCells; i++) {
          console.log("".concat(_index, ", ").concat(limit, ", ").concat(hasDigitsAfterDecimal));
          if (_index <= limit) {
            this.result.push(Math.ceil(tempResult));
          } else {
            this.result.push(Math.floor(tempResult));
          }
          _index++;
        }
      } else {
        for (var _i2 = 0; _i2 < this.countOfCells; _i2++) {
          this.result.push(tempResult);
        }
      }
      var index = 0;
      var tempNum = this.smallNum;
      for (var item in this.result) {
        var tempEndNum = tempNum + this.result[index];
        this.diapozoneResult[index] = "".concat(tempNum, "-").concat(tempEndNum);
        console.log(tempNum, " ".concat(item, " "), Number(this.result[index]));
        tempNum = tempEndNum;
        index++;
      }
    },
    getSum: function getSum() {
      this.sumResult = this.sumNum1 + this.sumNum2;
    }
  }
};