import "core-js/modules/es.function.name.js";
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  "class": "row gap-3 py-3 text-start needs-validation"
};
var _hoisted_2 = {
  "class": "col-12"
};
var _hoisted_3 = {
  "class": "flex flex-column gap-1"
};
var _hoisted_4 = {
  "for": "name"
};
import { computed, ref } from "vue";
import { useFetchDataStore } from "@/stores/fetchDataStore";
import { useErrorStore } from "@/stores/errorStore";
import AdminForm from "@/components/AdminForm.vue";
import InputError from "@/components/error/inputError.vue";
export default {
  __name: 'CreateCategory',
  setup: function setup(__props) {
    var fetchDataStore = useFetchDataStore();
    var instance = computed(function () {
      return fetchDataStore.instance;
    });
    var errorStore = useErrorStore();
    var errorList = computed(function () {
      return errorStore.errorList;
    });
    return function (_ctx, _cache) {
      var _component_InputText = _resolveComponent("InputText");
      return _openBlock(), _createBlock(AdminForm, {
        "page-name": _ctx.$t("pageHeadings.categories.categories"),
        "short-desc": _ctx.$t("pageHeadings.categories.create category"),
        "model-name": "Category",
        "database-table": "categories"
      }, {
        "default": _withCtx(function () {
          return [_createElementVNode("form", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.$t("fields.category.name")), 1), _createVNode(_component_InputText, {
            modelValue: _unref(instance).name,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
              return _unref(instance).name = $event;
            }),
            invalid: _unref(errorList).name,
            id: "name"
          }, null, 8, ["modelValue", "invalid"]), _createVNode(InputError, {
            errors: _unref(errorList).name
          }, null, 8, ["errors"])])])])];
        }),
        _: 1
      }, 8, ["page-name", "short-desc"]);
    };
  }
};