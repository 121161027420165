import _objectSpread from "/var/www/jjs_datu_uzskaites_un_parvaldibas_sistema/frontend/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.date.to-string.js";
import "core-js/modules/es.function.name.js";
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, toHandlers as _toHandlers, mergeProps as _mergeProps, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
var _hoisted_1 = {
  id: "editUserForm",
  "class": "row gap-3 py-3 text-start needs-validation"
};
var _hoisted_2 = {
  "class": "col-12"
};
var _hoisted_3 = {
  "class": "flex flex-column gap-1"
};
var _hoisted_4 = {
  "for": "name"
};
var _hoisted_5 = {
  "class": "col-12"
};
var _hoisted_6 = {
  "class": "flex flex-column gap-1"
};
var _hoisted_7 = {
  "for": "surname"
};
var _hoisted_8 = {
  "class": "col-12"
};
var _hoisted_9 = {
  "class": "flex flex-column gap-1"
};
var _hoisted_10 = {
  "for": "person_code"
};
var _hoisted_11 = {
  "class": "col-12"
};
var _hoisted_12 = {
  "class": "flex flex-column gap-1"
};
var _hoisted_13 = {
  "for": "birthdate"
};
var _hoisted_14 = {
  "class": "col-12"
};
var _hoisted_15 = {
  "class": "flex flex-column gap-1"
};
var _hoisted_16 = {
  "for": "email"
};
var _hoisted_17 = {
  "class": "col-12"
};
var _hoisted_18 = {
  "class": "flex flex-column gap-1"
};
var _hoisted_19 = {
  "for": "phone"
};
var _hoisted_20 = {
  "class": "col-12"
};
var _hoisted_21 = {
  "class": "flex flex-column gap-1"
};
var _hoisted_22 = {
  "for": "iban_code"
};
var _hoisted_23 = {
  "class": "col-12"
};
var _hoisted_24 = {
  "class": "flex flex-column gap-1"
};
var _hoisted_25 = {
  "for": "password"
};
var _hoisted_26 = {
  "class": "col-12"
};
var _hoisted_27 = {
  "class": "flex flex-column gap-1"
};
var _hoisted_28 = {
  "for": "password_confirmation"
};
var _hoisted_29 = {
  "class": "col-12"
};
var _hoisted_30 = {
  "for": "roles"
};
var _hoisted_31 = {
  "class": "row"
};
import { computed } from "vue";
import { useFetchDataStore } from "@/stores/fetchDataStore";
import { useErrorStore } from "@/stores/errorStore";
import AdminForm from "@/components/AdminForm.vue";
import InputError from "@/components/error/inputError.vue";
export default {
  __name: 'EditUser',
  setup: function setup(__props) {
    var fetchDataStore = useFetchDataStore();
    var instance = computed(function () {
      return fetchDataStore.instance;
    });
    var errorStore = useErrorStore();
    var errorList = computed(function () {
      return errorStore.errorList;
    });
    return function (_ctx, _cache) {
      var _component_InputText = _resolveComponent("InputText");
      var _component_InputMask = _resolveComponent("InputMask");
      var _component_Calendar = _resolveComponent("Calendar");
      var _component_vue_tel_input = _resolveComponent("vue-tel-input");
      var _component_Password = _resolveComponent("Password");
      var _component_ToggleButton = _resolveComponent("ToggleButton");
      return _ctx.can('edit instances') && _ctx.can('manage users') ? (_openBlock(), _createBlock(AdminForm, {
        key: 0,
        "database-table": "users",
        "model-name": "User",
        "page-name": _ctx.$t("pageHeadings.users.users"),
        "short-desc": _ctx.$t("pageHeadings.users.edit user")
      }, {
        "default": _withCtx(function () {
          var _unref$iban_code, _unref$password, _unref$password_confi;
          return [_createElementVNode("form", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.$t("fields.user.name")), 1), _createVNode(_component_InputText, {
            id: "name",
            modelValue: _unref(instance).name,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
              return _unref(instance).name = $event;
            }),
            disabled: _unref(fetchDataStore).isFetching || _unref(fetchDataStore).isProcessing,
            invalid: _unref(errorList).name,
            d: "",
            maxlength: "60",
            pattern: "A:[A-ž\\s\\-]:multiple"
          }, null, 8, ["modelValue", "disabled", "invalid"]), _createVNode(InputError, {
            errors: _unref(errorList).name
          }, null, 8, ["errors"])])]), _createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, [_createElementVNode("label", _hoisted_7, _toDisplayString(_ctx.$t("fields.user.surname")), 1), _createVNode(_component_InputText, {
            id: "surname",
            modelValue: _unref(instance).surname,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
              return _unref(instance).surname = $event;
            }),
            disabled: _unref(fetchDataStore).isFetching || _unref(fetchDataStore).isProcessing,
            invalid: _unref(errorList).surname,
            maxlength: "60"
          }, null, 8, ["modelValue", "disabled", "invalid"]), _createVNode(InputError, {
            errors: _unref(errorList).surname
          }, null, 8, ["errors"])])]), _createElementVNode("div", _hoisted_8, [_createElementVNode("div", _hoisted_9, [_createElementVNode("label", _hoisted_10, _toDisplayString(_ctx.$t("fields.user.person_code")), 1), _createVNode(_component_InputMask, {
            id: "person_code",
            modelValue: _unref(instance).person_code,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) {
              return _unref(instance).person_code = $event;
            }),
            disabled: _unref(fetchDataStore).isFetching || _unref(fetchDataStore).isProcessing,
            invalid: _unref(errorList).person_code,
            mask: "999999-99999",
            unmask: ""
          }, null, 8, ["modelValue", "disabled", "invalid"]), _createVNode(InputError, {
            errors: _unref(errorList).person_code
          }, null, 8, ["errors"])])]), _createElementVNode("div", _hoisted_11, [_createElementVNode("div", _hoisted_12, [_createElementVNode("label", _hoisted_13, _toDisplayString(_ctx.$t("fields.user.birthdate")), 1), _createVNode(_component_Calendar, {
            id: "birthdate",
            modelValue: _unref(instance).birthdate,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = function ($event) {
              return _unref(instance).birthdate = $event;
            }),
            disabled: _unref(fetchDataStore).isFetching || _unref(fetchDataStore).isProcessing,
            invalid: _unref(errorList).birthdate,
            maxDate: new Date(),
            dateFormat: "dd.mm.yy",
            panelClass: "min-w-min"
          }, null, 8, ["modelValue", "disabled", "invalid", "maxDate"]), _createVNode(InputError, {
            errors: _unref(errorList).birthdate
          }, null, 8, ["errors"])])]), _createElementVNode("div", _hoisted_14, [_createElementVNode("div", _hoisted_15, [_createElementVNode("label", _hoisted_16, _toDisplayString(_ctx.$t("fields.user.email")), 1), _createVNode(_component_InputText, {
            id: "email",
            modelValue: _unref(instance).email,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = function ($event) {
              return _unref(instance).email = $event;
            }),
            disabled: _unref(fetchDataStore).isFetching || _unref(fetchDataStore).isProcessing,
            invalid: _unref(errorList).email
          }, null, 8, ["modelValue", "disabled", "invalid"]), _createVNode(InputError, {
            errors: _unref(errorList).email
          }, null, 8, ["errors"])])]), _createElementVNode("div", _hoisted_17, [_createElementVNode("div", _hoisted_18, [_createElementVNode("label", _hoisted_19, _toDisplayString(_ctx.$t("fields.user.phone")), 1), _createVNode(_component_vue_tel_input, {
            modelValue: _unref(instance).phone,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = function ($event) {
              return _unref(instance).phone = $event;
            }),
            "auto-format": true,
            autocomplete: "off",
            mode: "international"
          }, {
            input: _withCtx(function (_ref) {
              var _unref$phone;
              var props = _ref.props,
                actions = _ref.actions,
                value = _ref.value,
                update = _ref.update;
              return [_createVNode(_component_InputText, _mergeProps({
                ref: "phone",
                disabled: _unref(fetchDataStore).isFetching || _unref(fetchDataStore).isProcessing,
                invalid: ((_unref$phone = _unref(errorList).phone) === null || _unref$phone === void 0 ? void 0 : _unref$phone.length) > 0,
                value: value
              }, props, {
                onInput: function onInput($event) {
                  return update($event.target.value);
                }
              }, _toHandlers(_objectSpread({}, actions))), null, 16, ["disabled", "invalid", "value", "onInput"])];
            }),
            _: 1
          }, 8, ["modelValue"]), _createVNode(InputError, {
            errors: _unref(errorList).phone
          }, null, 8, ["errors"])])]), _createElementVNode("div", _hoisted_20, [_createElementVNode("div", _hoisted_21, [_createElementVNode("label", _hoisted_22, _toDisplayString(_ctx.$t("fields.user.iban_code")), 1), _createVNode(_component_InputMask, {
            id: "iban_code",
            modelValue: _unref(instance).iban_code,
            "onUpdate:modelValue": _cache[6] || (_cache[6] = function ($event) {
              return _unref(instance).iban_code = $event;
            }),
            disabled: _unref(fetchDataStore).isFetching || _unref(fetchDataStore).isProcessing,
            invalid: ((_unref$iban_code = _unref(errorList).iban_code) === null || _unref$iban_code === void 0 ? void 0 : _unref$iban_code.length) > 0,
            mask: "aa*************?******************"
          }, null, 8, ["modelValue", "disabled", "invalid"]), _createVNode(InputError, {
            errors: _unref(errorList).iban_code
          }, null, 8, ["errors"])])]), _createElementVNode("div", _hoisted_23, [_createElementVNode("div", _hoisted_24, [_createElementVNode("label", _hoisted_25, _toDisplayString(_ctx.$t("fields.user.password")), 1), _createVNode(_component_Password, {
            id: "password",
            modelValue: _unref(instance).password,
            "onUpdate:modelValue": _cache[7] || (_cache[7] = function ($event) {
              return _unref(instance).password = $event;
            }),
            disabled: _unref(fetchDataStore).isFetching || _unref(fetchDataStore).isProcessing,
            invalid: ((_unref$password = _unref(errorList).password) === null || _unref$password === void 0 ? void 0 : _unref$password.length) > 0,
            inputStyle: "width: 100%",
            toggleMask: ""
          }, null, 8, ["modelValue", "disabled", "invalid"]), _createVNode(InputError, {
            errors: _unref(errorList).password
          }, null, 8, ["errors"])])]), _createElementVNode("div", _hoisted_26, [_createElementVNode("div", _hoisted_27, [_createElementVNode("label", _hoisted_28, _toDisplayString(_ctx.$t("fields.user.password_confirmation")), 1), _createVNode(_component_Password, {
            id: "password_confirmation",
            modelValue: _unref(instance).password_confirmation,
            "onUpdate:modelValue": _cache[8] || (_cache[8] = function ($event) {
              return _unref(instance).password_confirmation = $event;
            }),
            disabled: _unref(fetchDataStore).isFetching || _unref(fetchDataStore).isProcessing,
            invalid: ((_unref$password_confi = _unref(errorList).password_confirmation) === null || _unref$password_confi === void 0 ? void 0 : _unref$password_confi.length) > 0,
            inputStyle: "width: 100%",
            toggleMask: ""
          }, null, 8, ["modelValue", "disabled", "invalid"]), _createVNode(InputError, {
            errors: _unref(errorList).password_confirmation
          }, null, 8, ["errors"])])]), _createElementVNode("div", _hoisted_29, [_createElementVNode("label", _hoisted_30, _toDisplayString(_ctx.$t("fields.user.roles")), 1), _createElementVNode("div", _hoisted_31, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(instance).all_roles, function (role, index) {
            var _unref$role;
            return _openBlock(), _createElementBlock("div", {
              key: index,
              "class": "col-12 col-md-4 d-flex align-items-center"
            }, [_createVNode(_component_ToggleButton, {
              modelValue: role.userHas,
              "onUpdate:modelValue": [function ($event) {
                return role.userHas = $event;
              }, _cache[9] || (_cache[9] = function ($event) {
                return console.log(_unref(instance).roles);
              })],
              disabled: _unref(fetchDataStore).isFetching || _unref(fetchDataStore).isProcessing,
              invalid: (_unref$role = _unref(errorList).role) === null || _unref$role === void 0 ? void 0 : _unref$role.name,
              "off-label": role.name,
              "on-label": role.name,
              "class": "w-full h-full"
            }, null, 8, ["modelValue", "onUpdate:modelValue", "disabled", "invalid", "off-label", "on-label"])]);
          }), 128))])])])];
        }),
        _: 1
      }, 8, ["page-name", "short-desc"])) : _createCommentVNode("", true);
    };
  }
};